import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import omit from 'lodash-es/omit';
import { throwError } from 'rxjs';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import { catchError, tap } from 'rxjs/operators';

import { ValidationService } from '../../../forms/validation.service';
import { AppState } from '../../index';
import { undo } from '../../shared/undo/undoAction';
import { TimeOffAccrual } from '../contract/contract.model';
import { TimeOffBalanceAction } from './time-off-balance.action';
import { TimeOffBalanceApi } from './time-off-balance.api';
import { TimeOffBalanceModel, TimeOffBalanceUpdateModel } from './time-off-balance.model';

@Injectable()
export class TimeOffBalanceService {
  public constructor(
    private api: TimeOffBalanceApi,
    private store: Store<AppState>,
  ) {}

  public load() {
    return this.api.load(TimeOffBalanceAction.load()).pipe(
      tap((data) => {
        this.store.dispatch(TimeOffBalanceAction.loadSuccess(data));
      }),
      catchError((response) => {
        this.store.dispatch(TimeOffBalanceAction.loadFailed(response));
        return throwError(response);
      }),
    );
  }

  public save(data: TimeOffBalanceModel) {
    return this.api.save(data, TimeOffBalanceAction.add()).pipe(
      tap((response) => {
        this.store.dispatch(TimeOffBalanceAction.addSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(TimeOffBalanceAction.addFailed(response));
        return throwError(response);
      }),
    );
  }

  public update(id: string, data: TimeOffBalanceUpdateModel) {
    return this.api.update(id, data, TimeOffBalanceAction.update()).pipe(
      tap((response) => {
        this.store.dispatch(TimeOffBalanceAction.updateSuccess({ id, changes: omit(response, ['id']) }));
      }),
      catchError((response) => {
        this.store.dispatch(TimeOffBalanceAction.updateFailed(response));
        return throwError(response);
      }),
    );
  }

  public createMapping(timeOffBalances: TimeOffBalanceModel[], timeOffAccrual: TimeOffAccrual, disableAccrual = false) {
    const timeOffBalanceFields = [];
    const formArray = new UntypedFormArray([]);
    timeOffBalances.forEach((timeOffBalance, index) => {
      const timeOffAccrualValue =
        timeOffAccrual && timeOffAccrual.hasOwnProperty(timeOffBalance.id)
          ? timeOffAccrual[timeOffBalance.id]
          : timeOffBalance?.default_accrual;

      const form = new UntypedFormGroup({
        id: new UntypedFormControl(timeOffBalance.id),
        value: new UntypedFormControl(timeOffAccrualValue, [
          Validators.required,
          ValidationService.negativeNumberValidator,
        ]),
      });
      if (disableAccrual) {
        form.disable();
      }

      timeOffBalanceFields.push({
        ...timeOffBalance,
        form,
      });
      formArray.push(form);
    });

    return {
      fields: timeOffBalanceFields,
      formArray,
    };
  }

  public mapFormDataToRequestData = (timeOffAccrualFormData): TimeOffAccrual => {
    const timeOffAccrualList: TimeOffAccrual = {};
    timeOffAccrualFormData.forEach((timeOffAccrual) => {
      timeOffAccrualList[timeOffAccrual.id] = parseFloat(timeOffAccrual.value);
    });

    return timeOffAccrualList;
  };

  public getTimeOffBalanceForUserAndDate(userId: string, date: string) {
    return this.api.getTimeOffBalanceForUserAndDate(userId, date);
  }

  public getTimeOffSummaryForUserInYear(userId: string, year: string) {
    return this.api.getTimeOffSummaryForUserInYear(userId, year);
  }

  public deactivate(id: string) {
    const deactiveAction = TimeOffBalanceAction.deactivate(id);
    this.store.dispatch(deactiveAction);

    return this.api.deactivate(id).pipe(
      tap(() => {
        this.store.dispatch(TimeOffBalanceAction.deactivateSuccess(id));
      }),
      catchError((response) => {
        this.store.dispatch(undo(deactiveAction));
        this.store.dispatch(TimeOffBalanceAction.activateFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public activate(id: string) {
    const activateAction = TimeOffBalanceAction.activate(id);
    this.store.dispatch(activateAction);

    return this.api.activate(id).pipe(
      tap(() => {
        this.store.dispatch(TimeOffBalanceAction.activateSuccess(id));
      }),
      catchError((response) => {
        this.store.dispatch(undo(activateAction));
        this.store.dispatch(TimeOffBalanceAction.activateFailed(response));
        return observableThrowError(response);
      }),
    );
  }
}
