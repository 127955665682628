import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction } from '../../interfaces';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { TimeOffBalanceModel, TimeOffBalanceUpdateModel } from './time-off-balance.model';

@Injectable()
export class TimeOffBalanceApi {
  private endpoint = 'time_off_balances';

  public constructor(private gateway: ApiGateway) {}

  public load(dispatchStart?: UnsafeAction) {
    return this.gateway
      .get(this.endpoint, null, dispatchStart)
      .pipe(map((res) => reformatListResponse('TimeOffBalance', res)));
  }

  public save(data: TimeOffBalanceModel, dispatchStart?: UnsafeAction) {
    return this.gateway
      .post(this.endpoint, data, null, dispatchStart)
      .pipe(map((res) => reformatEntityResponse('TimeOffBalance', res)));
  }

  public update(id: string, data: TimeOffBalanceUpdateModel, dispatchStart?: UnsafeAction) {
    return this.gateway
      .put(`${this.endpoint}/${id}`, data, null, dispatchStart)
      .pipe(map((res) => reformatEntityResponse('TimeOffBalance', res)));
  }

  public getTimeOffBalanceForUserAndDate(userId: string, date: string): Observable<{ [balanceId: string]: number }> {
    return this.gateway.get(`users/${userId}/time_off/balances`, { params: { date: date } });
  }

  public getTimeOffSummaryForUserInYear(
    userId: string,
    year: string,
  ): Observable<{
    [balanceId: string]: {
      accrued: number;
      adjusted: number;
      end: number;
      start: number;
      taken: number;
      waitHours: number;
    };
  }> {
    return this.gateway.get(`users/${userId}/time_off/balances/summary`, { params: { year: year } });
  }

  public deactivate(id: string): Observable<any> {
    return this.gateway.get(`time_off_balances/${id}/deactivate`);
  }

  public activate(id: string): Observable<any> {
    return this.gateway.get(`time_off_balances/${id}/activate`);
  }
}
